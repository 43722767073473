import React from "react";
import { SiteWrapper } from "../components/SiteWrapper";
import Seo from "../components/seo";
import useWindowDimensions from "../utils/windowSize";
import { Link } from "gatsby";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ACTIVE_PAGE } from "../components/nav/utils";

export default function PointCloudPage() {
  const { width } = useWindowDimensions();
  const desktopPointCloud = () => (
    <iframe
      src="https://www.youtube.com/embed/Byq7eWVOW0M?autoplay=1&mute=1&controls=0&showinfo=0&loop=1&playlist=Byq7eWVOW0M"
      className="deliverable__iframe"
      scrolling="no"
      frameBorder="0"
      allowFullScreen
      title="point-cloud"
      allow="autoplay"
    />
  );
  const mobilePointCloud = () => (
    <img
      className="point-cloud-video"
      src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fpoint-cloud%2Fnew-septa-gif.gif?alt=media&token=763f4c9b-069e-4f88-8ec3-e0efd6ce99e9&_gl=1*trihs*_ga*MjM4OTYzNDY0LjE2OTQxMDMwOTE.*_ga_CW55HF8NVT*MTY5NzQ3NTUyNS44NC4xLjE2OTc0Nzc0NTYuMjQuMC4w"
      alt=""
    />
  );

  return (
    <SiteWrapper activeItem={ACTIVE_PAGE.POINTCLOUD}>
      <Seo
        title="Point Cloud Services | Robotic Imaging"
        description="Sample Point Cloud services provided by Robotic Imaging."
      />
      <header className="site__header"></header>

      {width ? (width >= 650 ? desktopPointCloud() : mobilePointCloud()) : null}

      <main className="content">
        <h2>What are Point Clouds?</h2>
        <p>
          <u>Point Clouds</u> are plotted data points representing space, with
          each point in an X, Y, Z coordinate to represent a surface. These data
          points collectively capture entire interior and exterior spaces,
          sometimes including additional information like color in an RGB
          format.
        </p>

        <video
          className="point-cloud-gif"
          src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2FPointCloud%2Fnew%20Point%20Cloud%20Animation_5MB.mp4?alt=media&token=3871d8ba-62b7-4f41-b46e-84234373cf31&_gl=1*11v5fsf*_ga*NTg2OTE3MTgyLjE2OTkzODUxMjI.*_ga_CW55HF8NVT*MTY5OTM4NTEyMS4xLjEuMTY5OTM4NTMwOS43LjAuMA.."
          autoPlay
          loop
          muted
          playsInline
          // alt=""
        />

        <p>
          At Robotic Imaging, we utilize LiDAR technology for point cloud data
          collection, ensuring highly accurate scans that are then stitched
          together through a process called "registration".
        </p>
        <p>
          Point clouds hold immense value for architects, developers, and
          construction managers as they can be converted and translated into BIM
          models, ideal for construction projects.
        </p>

        <video
          className="video"
          src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-videos%2F2D%2F182Flatbush%20Ave_B.mp4?alt=media&token=a6120132-0e11-48e3-b4e8-f7cc5e34f9a0"
          autoPlay
          loop
          muted
          playsInline
          // alt=""
          style={{ maxHeight: "400px" }}
        />

        <br />

        <h2>Project Unknowns Cost A LOT!</h2>
        <p>
          We help construction professionals remotely focus on design and
          project execution. Our point clouds come in a variety of formats that
          can be upgraded for the most complex building. We save you time and
          prevent future uncoordinated&nbsp;
          <Link to="/clashdetection" className="yellow-link">
            clashes
          </Link>
          . Our team is dedicated to helping design professionals eliminate
          margins for error.
        </p>

        <img
          className="img"
          src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/page-images%2Fpoint-cloud%2FScan%20the%20City%20point%20cloud.png?alt=media&token=84b8adb9-ac31-4ba5-9dbf-6427bf248b08"
          alt=""
          style={{ maxWidth: "1050px" }}
        />

        <div className="page-navigation">
          <Link to="/vr" className="page-navigation-prev" title="Previous page">
            <div>
              <ChevronLeftIcon />
              <span>Previous Page</span>
            </div>
            {width && width >= 650 ? <span>Visual</span> : null}
          </Link>
          <Link
            to="/aerial-drone"
            className="page-navigation-next"
            title="Next page"
          >
            <div>
              <span>Next Page</span>
              <ChevronRightIcon />
            </div>
            {width && width >= 650 ? <span>Drone</span> : null}
          </Link>
        </div>
      </main>
    </SiteWrapper>
  );
}
